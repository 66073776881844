<template>
  <div class="main index" v-if="!isLoading">
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Settings</b></h1>
      </div>
      <div class="column is-10">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Setting</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">User</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-2">
        <router-link :to="{ name: `createUser` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc">Create</b-button>
          </b-field>
        </router-link>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <span class="column is-4">
          <b-field>
            <b-input
              v-model="search"
              placeholder="Search..."
              type="search"
              icon="search"
            ></b-input>
          </b-field>
        </span>
        <span class="column is-8">
          <b-field grouped position="is-right">
            <b-select v-model="perPage" @input="onPageChange(currentPage)">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </b-select>
          </b-field>
        </span>
      </div>
      <div class="columns">
        <div class="column is-12">
          <!-- <div class="box"> -->
          <b-table
            :data="getUsers && getUsers.data ? getUsers.data : []"
            :current-page.sync="currentPage"
            paginated
            backend-pagination
            :total="getUsers && getUsers.total ? getUsers.total : 0"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
          >
            <b-table-column field="index" label="No" v-slot="props">
              {{ props.index + getUsers.from }}
            </b-table-column>
            <b-table-column field="name" label="Name" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="email" label="Email" sortable v-slot="props">
              {{ props.row.email }}
            </b-table-column>
            <b-table-column
              field="createdAt"
              label="Create At"
              sortable
              v-slot="props"
            >
              {{ props.row.createdAt }}
            </b-table-column>
            <!-- <b-table-column field="updatedAt" label="Last Update" sortable>
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">{{
                    column.label
                  }}</span>
                </template>
                {{ props.row.updatedAt }}
              </b-table-column> -->
            <b-table-column
              field="action"
              centered
              label="Action"
              v-slot="props"
            >
              <router-link :to="{ path: `/user/edit/${props.row.id}` }">
                <b-button type="icon-button" icon-left="pencil-alt"></b-button>
              </router-link>

              <!-- <b-button
                type="icon-button"
                icon-left="trash"
                @click="deletePopup(props.row.id, props.index)"
                :disabled="props.row.userType == 1"
              ></b-button> -->
            </b-table-column>
            <template slot="bottom-left">
              {{ getUsers && getUsers.from ? getUsers.from : 0 }} -
              {{ getUsers && getUsers.to ? getUsers.to : 0 }} from
              {{ getUsers && getUsers.total ? getUsers.total : 0 }}
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Data not found.</p>
                </div>
              </section>
            </template>
          </b-table>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'id',
      sortOrder: 'asc',
      page: 1,
      search: null,
    }
  },
  mounted() {
    this.fetchData(this.perPage, this.currentPage)
  },
  watch: {
    search: function(val) {
      this.searchUser(val)
    },
  },
  methods: {
    deletePopup(id, index) {
      this.$buefy.dialog.confirm({
        title: 'Delete User',
        message: `Are you sure want to delete this user?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteUser(id, index),
      })
    },
    searchUser(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }
    },
    async fetchData(perPage, page, sortField, sortOrder, search) {
      await this.$store.dispatch('getUsers', {
        perPage,
        page,
        sortField,
        sortOrder,
        search,
      })
    },
    deleteUser(id, index) {
      this.$store
        .dispatch('deleteUser', { id, index })
        .then(() => {
          this.success('Delete Success')
        })
        .catch(() => {
          this.danger('Cannot delete user')
        })
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
  },
  computed: {
    ...mapGetters(['getUsers']),
  },
}
</script>
